import { graphql } from "gatsby";
import React, {useState} from "react";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";
import { SEO } from "../../components/SEO";
import { PageProps } from "../../types/global";
import { navigate } from 'gatsby-link';
import { SiMinutemailer, SiCoffeescript, SiFacebook, SiLinkedin, SiInstagram, SiWhatsapp, SiTwitter, SiGithub, SiGitlab, SiDiscord, SiTelegram, SiGmail } from "react-icons/si";
import { RiUser3Line } from "react-icons/ri";
import { BsChatText } from "react-icons/bs";
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"
import { FaHeadSideMask } from "react-icons/fa";
import MyMap from "~/components/myMap";
import {BiCoffeeTogo} from "react-icons/bi";
import ContactDetail from "~/components/contactDetail";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            
          }
    }
`;
const GetInTouch = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5rem;
`;

export const Buttona = styled.a`
    padding: 1rem;
    font-size: 2rem;
    color: #ffffff;
    background-color:  #4b2812;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
`;

const Message = styled.p`
    a {
        padding: 0.5rem;
        width: 1rem;
        height: 1rem;
        text-decoration: none;
        color: white;
    }
`;
const MainText = styled.p`
    display: table-row;
`;
const MainTextCenter = styled(MainText)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 1rem;
`;
const Center= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Label = styled.label`
    display: table-cell;
    font-size: 1.2em;
    color: #bfc6bf;
    font-family: "Roboto";
    font-weight: 400;
    margin: 0;
    text-align: left;
    padding: 1rem;
`;
const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    height: 100%;
    overflow: hidden;
`;
const Form = styled.form`
    display: table;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    padding: 50px;
`;

const Input = styled.input`
    background: transparent;
    width: 100%;
    clear: both;
    color: white;
    display: table-cell;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 15px;
    background-color: #383838;
    font-family: 'Gloria Hallelujah';
`;
const TextArea = styled.textarea`
    background: transparent;
    width: 100%;
    clear: both;
    color: white;
    display: table-cell;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 15px;
    background-color: #383838;
    font-family: 'Gloria Hallelujah';
`;

const Button = styled.button`
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    padding: 0.2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    color: #bfc6bf;
    font-family: "Roboto";
    font-weight: 400;
`;

const ContactUS = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2.5em;
    color: #797979;
    font-family: "Roboto";
    font-weight: 400;
    padding: 0 0 1rem 0;
`;
const SiCoffeescriptWhite = styled(SiCoffeescript)`
    color: #fffddd;
    background: transparent;
    width: 3rem;
    height: 3rem;
    padding: 0 2rem;
`;
const SiFacebookSize = styled(SiFacebook)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 0.3s all;
    
        color: #0077ff;
    
`;
const SiInstagraSize = styled(SiInstagram)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    
        color: #d6249f;
     
`;
const SiWhatsappSize = styled(SiWhatsapp)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    
        color: #0afd17;

`;
const SiTwitterSize = styled(SiTwitter)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    
        color: #00c3ff;
      
`;
const SiGithubSize = styled(SiGithub)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    
        color: #5b00c4;
     
`;
const SiGitlabSize = styled(SiGitlab)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    
        color: #ff5e00;
     
`;
const SiDiscordSize = styled(SiDiscord)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    
        color: #4e3fdb;
   
`;
const SiTelegramSize = styled(SiTelegram)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    
        color: #0099ffc8;
     
`;
const SiLinkedinSize = styled(SiLinkedin)`
@media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    
        color: #044fb1;
     
`;

const SiGmailSize = styled(SiGmail)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    
        color: red;
    
`;
function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  
const ContactPage: React.FunctionComponent<PageProps> = (props)=> {
    const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  } 
    const strings = useStringsContext();
    const [submitted, setSubmitted] = useState(false)
    const submit = (token) => {}
    return (
    <>
    
    <SEO
                lang={props.pageContext.language}
                title="Contact Me"
                description="Technical Project Manager with over 9 years of experience and with a large set of supporting skills across various platforms using latest industry-adopted technologies and frameworks. Experienced in Typescript, Javascript, React, Gatsby, Adobe XD, UX/UI Design, Git, Node.js, HTML, CSS, Styled Components, React Spring, AWS, Imgix, HeadLess CMS, Creative thinking, Team lead, mentor to Junior Developers. Worked with various Teams from different parts of the world over the year in different projects and creating and delivering user-centric applications and solutions. Led an agile team of five core developers and created a system of collaboration that involves fifteen remote collaborators. Refactored old websites and launched new ones with first-class scalability and security. Upgraded infrastructure to modern tech stacks, cut costs in half, enhanced both front-end and back-end security, improved accessibility, and optimized performance on low-end devices. Maintained legacy codebases.  Refactored legacy code to use modern technologies and best practices. Streamlined and automated production pipelines to minimize manual intervention. Worked with a global team of translators."
                keywords={["saba"]}
                url={props.location.pathname}
            />
    <Container>
        <ContactUS>Get in touch<SiCoffeescriptWhite/></ContactUS>
        <Form 
            name="contact"
            method="post"
            action="/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
                <label>Get in touch with me <input name="bot-field" onChange={handleChange} />
                </label>
            </p>
            <MainText>
                <Label>Full Name: <RiUser3Line/><Input type="text" name="name" onChange={handleChange}/></Label>   
            </MainText>
            <MainText>
                <Label>Email ID: <SiMinutemailer/><Input type="email" name="email" onChange={handleChange}/></Label>
            </MainText>
            <MainText>
                <Label>Text me: <BsChatText/><TextArea name="message" onChange={handleChange}></TextArea></Label>
            </MainText>
            <MainTextCenter>
                <Button data-action='submit' onClick={() => setSubmitted(true)} type="submit">Send</Button>
            </MainTextCenter>
            <Center>
                <ReCaptcha
                action="submit"
                siteKey="6LdySeYdAAAAAPy3krM19iGcB5UenTljpjX57KkX"
                onVerify={(token) => submit(token)}
                submitted={submitted}
                size="invisible"
            />
            </Center>
            <GetInTouch>
            <Message>
                <a href="https://www.facebook.com/saba.hossein"><SiFacebookSize/></a>
                <a href="https://www.instagram.com/sabahossein_019?r=nametag"><SiInstagraSize/></a>
                <a href="https://wa.me/+919637304919"><SiWhatsappSize/></a>
                <a href="https://twitter.com/sabahossein19?t=rEX2xipzlIvllwBGqql9Yw&amp;s=08"><SiTwitterSize/></a>
                <a href="linkedin.com/in/saba-hossein-b5a871120"><SiLinkedinSize/></a>
                <a href="https://github.com/sabahossein"><SiGithubSize/></a>
                <a href="https://gitlab.com/sahossein"><SiGitlabSize/></a>
                <a href="https://discord.gg/PUxx2s45BC"><SiDiscordSize/></a>
                <a href="https://t.me/Sabahossein"><SiTelegramSize/></a>
                <a href="mailto: sabahossein.work@gmail.com"><SiGmailSize/></a>
            </Message>
        </GetInTouch>
        </Form>
    </Container>
    <Container style={{minHeight: "100%", paddingBottom: "5rem"}}><Buttona href="https://rzp.io/l/2M9xD4Ks" target="_blank">Buy Me a Coffee<BiCoffeeTogo style={{padding: "0 .5rem"}}/></Buttona></Container>
    <ContactDetail/>
    <MyMap />
    </>
    )
}

export default ContactPage
