import React, { Component } from 'react'
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';
import styled from "styled-components";

const Div = styled.div`
    width: 100%;
    height: 20rem;
`;

export default class MyMap extends Component {
  render() {

    if (typeof window !== 'undefined') {
      return (
        <>
        <Div>
        {(typeof window !== "undefined") ? (
          <MapContainer style={{height: "25rem", width:"100%"}} center={[18.553832113393216, 73.8983784074074]} zoom={13} scrollWheelZoom={false}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={[18.553832113393216, 73.8983784074074]}>
            <Popup>
                Hi there,<br /> That's my work address <br /> <a href="https://goo.gl/maps/3U2KQ26YPLN7jMvg8">SABA HOSSEIN</a>
            </Popup>
            </Marker>
          </MapContainer>
        ) : null}
      </Div>
      </>
    )
  }
  return null
}
}